import GoogleTagManagerConfig from '@/configs/google-tag-manager-config.js';

window.dataLayer = window.dataLayer || [];

function gtag() {
    if (GoogleTagManagerConfig.CONTAINER_ID) {
        window.dataLayer.push(arguments);
    }
}

function initializeGTM() {
    if (GoogleTagManagerConfig.CONTAINER_ID) {
        // GTM initialization script.
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', GoogleTagManagerConfig.CONTAINER_ID);

        // GTM additional settings for initialization.
        gtag('consent', 'default', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500,
        });

        gtag('set', 'ads_data_redaction', true);
    }
}

export { gtag, initializeGTM };
import JwtConfig from '@/configs/jwtConfig';
import jwt_decode from "jwt-decode";

export default class Auth {
    constructor() {
        this.authenticated = false;
        this.tokenRaw      = null;
    }

    isAuth() {
        return this.authenticated;
    }

    authenticate() {
        this.getTokenSessionStorage();

        // Session is set check
        if (!this.getTokenRaw()) {
            this.killAuth();

            return false;
        }

        try {
            let jwtTokenDecoded = jwt_decode(this.getTokenRaw());

            let tokenExpiryTime = jwtTokenDecoded.exp;                     // We get this in seconds
            let currentTimeNow  = parseInt((new Date().getTime() / 1000)); // We need to convert from milliseconds into seconds

            // Validate token expiry time
            if (tokenExpiryTime < currentTimeNow || tokenExpiryTime <= 0) {
                this.killAuth();

                return false;
            }

            // We are authenticated
            this.setAuthenticated();

            return true;
        } catch(e) {
            this.killAuth();

            return false;
        }
    }

    getTokenSessionStorage() {
        this.tokenRaw = sessionStorage.getItem(JwtConfig.TOKEN_KEY);
    }

    getTokenRaw() {
        return this.tokenRaw;
    }

    setTokenSessionStorage(token) {
        sessionStorage.setItem(JwtConfig.TOKEN_KEY, token);
    }

    setAuthenticated() {
        this.authenticated = true;
    }

    resetTokenRaw() {
        this.tokenRaw = null;
    }

    resetAuthenticated() {
        this.authenticated = false;
    }

    killAuth() {
        sessionStorage.removeItem(JwtConfig.TOKEN_KEY);
        this.resetAuthenticated();
        this.resetTokenRaw();

        // If we have child method killUser() in chain then kill the user
        if (this.killUser) {
            this.killUser();
        }
    }
}

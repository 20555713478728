export default class EntryManager {
    constructor() {
        this.entryPage = null;
    }

    setEntryPage(entryPage) {
        if (!this.getEntryPage() && entryPage) {
            this.entryPage = entryPage;
        }
    }

    getEntryPage() {
        return this.entryPage;
    }
}

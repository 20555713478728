<template>
  <default :classes="classes" :disabled="disabled" :spinner="spinner">
    <slot/>
  </default>
</template>

<script>
  import Default from '@/components/ui/button/components/Default';

  export default {
    name: "ButtonPrimary",

    props: {
      disabled: {
        default: false,
        type: Boolean
      },
      spinner: {
        default: false,
        type: Boolean
      }
    },

    components: {
      Default,
    },

    data: function () {
      return {
        classes: 'button-primary',
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>

import MetaHandlerConfig from '@/configs/metaHandlerConfig';

export const mixinMetaHandler = {
  methods: {
    _mixinSetPageTitle() {
      if (this.$route.meta.pageTitle) {
        document.title = this.$route.meta.pageTitle + ' | ' +  MetaHandlerConfig.DEFAULT_TITLE;
        return;
      }

      document.title = MetaHandlerConfig.DEFAULT_TITLE;
    },

    _mixinSetMetaTitle() {
      let title = MetaHandlerConfig.DEFAULT_TITLE;

      if (this.$route.meta.title) {
        title = this.$route.meta.title;
      }

      document.querySelector('meta[name="title"]').setAttribute("content", title);
    },

    _mixinSetMetaDescription() {
      let description = '';

      if (this.$route.meta.description) {
        description = this.$route.meta.description;
      }

      document.querySelector('meta[name="description"]').setAttribute("content", description);
    },

    _mixinSetMetaKeywords() {
      let keywords = '';

      if (this.$route.meta.keywords) {
        keywords = this.$route.meta.keywords;
      }

      document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
    },

    _mixinSetMetaRobotsNoIndex() {
      if (this.$route.meta.noIndex && !document.querySelector('meta[name="robots"]')) {
        let meta     = document.createElement('meta');
        meta.name    = 'robots';
        meta.content = 'noindex';

        document.getElementsByTagName('head')[0].appendChild(meta);
      }
    },

    _mixinRemoveMetaRobotsNoIndex() {
      if (document.querySelector('meta[name="robots"]')) {
        document.querySelector('meta[name="robots"]').remove();
      }
    },

    _mixinMetaHandlerInit() {
      this._mixinSetPageTitle();
      this._mixinSetMetaTitle();
      this._mixinSetMetaDescription();
      this._mixinSetMetaKeywords();
      this._mixinSetMetaRobotsNoIndex();
    }
  },

  mounted() {
    this._mixinMetaHandlerInit();
  },

  watch: {
    $route() {
      this._mixinRemoveMetaRobotsNoIndex();
      this._mixinMetaHandlerInit();
    },
  }
};

export const mixinWindowResizeListener = {
  data: function () {
    return {
      mixinWindowResizeListenerData: {
        width: 0,
        height: 0
      }
    }
  },

  methods: {
    _mixinWindowResizeListenerOnResize() {
      this.mixinWindowResizeListenerData.width  = window.innerWidth;
      this.mixinWindowResizeListenerData.height = window.innerHeight;
    },
  },

  created() {
    this._mixinWindowResizeListenerOnResize();
    window.addEventListener('resize', this._mixinWindowResizeListenerOnResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this._mixinWindowResizeListenerOnResize);
  },
};

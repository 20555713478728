import TranslationsConfig from '@/configs/translationsConfig';

export default class Translations {
    constructor() {
        this.translations = {};
        this.getTranslationsSessionStorage();
    }

    getTranslation(key) {
        if (this.translations[key]) {
            return this.translations[key];
        }

        return null;
    }

    getTranslationsSessionStorage() {
        try {
            let translations = JSON.parse(sessionStorage.getItem(TranslationsConfig.STORAGE_KEY));

            if (!translations) {
                return false;
            }

            this.setTranslations(translations);

            return true;
        } catch (e) {
            return false;
        }
    }

    setTranslationsSessionStorage(translations) {
        sessionStorage.setItem(TranslationsConfig.STORAGE_KEY, JSON.stringify(translations));
    }

    setTranslations(translations) {
        if (!translations || JSON.stringify(translations) === JSON.stringify({})) {
            return;
        }

        this.translations = translations;
        this.setTranslationsSessionStorage(translations)
    }
}

import AxiosConfig from '@/configs/axiosConfig';
import JwtConfig from '@/configs/jwtConfig';

let _axios = require('axios');

class Axios {
  constructor(formData, config) {
    this.formData = formData;
    this.config   = config;
    this.baseUrl  = AxiosConfig.BASE_API_URL;
  }

  get(cb) {
    this.baseRequest('get', function (response) {
      cb(response);
    });
  }

  // Request
  post(cb) {
    this.baseRequest('post', function (response) {
      cb(response);
    });
  }

  // Request
  patch(cb) {
    this.baseRequest('patch', function (response) {
      cb(response);
    });
  }

  // Request
  delete(cb) {
    this.baseRequest('delete', function (response) {
      cb(response);
    });
  }

  getFormData() {
    return this.formData;
  }

  getConfig() {
    return this.config;
  }

  getAuthHeaderToken() {
    return `Bearer ${this.authentication()}`;
  }

  authentication() {
    return sessionStorage.getItem(JwtConfig.TOKEN_KEY);
  }

  urlExtension() {
    return this.getConfig() && this.getConfig()['urlExtension'];
  }

  path() {
    return '';
  }

  baseRequest(requestType, cb) {
    // Set config defaults when creating the instance
    const instance = _axios.create({
      baseURL: `${this.baseUrl}/${this.path()}`,
    });

    // Alter defaults after instance has been created
    // Auth token
    if (this.authentication()) {
      instance.defaults.headers.common['Authorization'] = this.getAuthHeaderToken();
    }

    // Parameters
    let parameters = {};
    if (requestType === 'get') {
      parameters = this.getFormData();
    } else {
      parameters = this.getFormData()['params'];
    }

    // URL Extension
    let urlExtension = '';
    if (this.urlExtension()) {
      urlExtension = `/${this.getConfig()['urlExtension']}`;
    }

    instance[requestType](urlExtension, parameters)
      .then((response) => {
        cb(response);
      })
      .catch((error) => {
        if (error.response) {
          cb(error.response);
          return;
        }

        cb(error);
      });
  }
}

export default Axios;

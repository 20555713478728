export const mixinRoutesHandler = {
  methods: {
      _mixinGoToRouteName(routeName) {
          if (this.$route.name !== routeName) {
              this.$router.push({
                  name: routeName
              });
          }
      },

      _mixinGetRoutePath(routeName) {
          let declaredRoutes = this.$router.options.routes;
          for (let prop in declaredRoutes) {
              let hasBarProperty = Object.prototype.hasOwnProperty.call(declaredRoutes, prop);

              if (hasBarProperty && declaredRoutes[prop].name === routeName) {
                  return declaredRoutes[prop].path;
              }
          }

          return '#';
      },
  },
};

import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import VBurger from 'vue-burger';
import { initializeGTM } from '@/utilities/gtag.js';
import { initializeCookieConsent } from '@/utilities/fdm-cookie-consent.js';

import {
  LayoutPlugin,
  ModalPlugin,
  TablePlugin,
  NavbarPlugin,
  PaginationPlugin,
  SpinnerPlugin,
  AlertPlugin,
  BIcon,
  BIconBoxArrowUpRight,
  BIconGear,
  BIconPlus,
  BIconDash,
  BIconX,
  BIconChevronDown
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(TablePlugin);
Vue.use(NavbarPlugin);
Vue.use(PaginationPlugin);
Vue.use(SpinnerPlugin);
Vue.use(AlertPlugin);

Vue.component('BIcon', BIcon);
Vue.component('BIconBoxArrowUpRight', BIconBoxArrowUpRight);
Vue.component('BIconGear', BIconGear);
Vue.component('BIconPlus', BIconPlus);
Vue.component('BIconDash', BIconDash);
Vue.component('BIconX', BIconX);
Vue.component('BIconChevronDown', BIconChevronDown);

import VueMask from 'v-mask';

// CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// CSS Custom
import "@/assets/scss/_fonts.scss";
import "@/assets/scss/_normalization.scss";
import "@/assets/scss/_bootstrapResets.scss";

Vue.use(VueMask);
Vue.use(VBurger);

Vue.directive('mask', VueMask.VueMaskDirective);

Vue.config.productionTip = false

// Add classes
import User from '@/models/user';
import Translations from '@/models/translations';
import EntryManager from '@/models/entryManager';

// Vue globals
Vue.prototype.$user = new User();
Vue.prototype.$translations = new Translations();
Vue.prototype.$entryManager = new EntryManager();
Vue.prototype.$moment = require('moment');

initializeGTM();
initializeCookieConsent();

new Vue({
  data: function() {
    return {
      userClass: this.$user, // User class vue data bind
      translationsClass: this.$translations, // Translations class vue data bind
      entryManagerClass: this.$entryManager, // Translations class vue data bind
    }
  },

  methods: {
    killAuth() {
      this.$user.killAuth();

      if (this.$route.name === 'LinkCard') {
        this.$root.$emit('ls::global-alert::error', this.$translations.getTranslation('kill_auth_general_error_message'));
        return;
      }

      this.$router.push({
        name: 'LinkCard',
          params: {
            showKillAuthError: true,
          }
      });
    }
  },

  render: h => h(App),

  router,

}).$mount('#app')

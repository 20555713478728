import Vue from 'vue';
import VueRouter from 'vue-router';
import { gtag } from '@/utilities/gtag.js';
import GoogleTagManagerConfig from '@/configs/google-tag-manager-config.js';

// Configs
import loginConfig from '@/configs/loginConfig';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'LinkCard',
        meta: {
            pageTitle: 'Tilknyt betalingskort',
            title: 'Tilknyt dit betalingskort til FDM-fordele.',
            description: 'Få rabat hos alle FDMs fordelspartnere. Du skal blot tilknytte dit Dankort, Visa eller Mastercard til FDM-fordele, og så er du i gang. Tilknyt dit kort her.',
        },
        props: true,
        component: () => import(/* webpackChunkName: "LinkCard" */ '@/views/linkCard/LinkCard'),
    },
    {
        path: '/administrer-kort',
        name: 'ManageCards',
        meta: {
            pageTitle: 'Administrér betalingskort',
            title: 'Administrer dine kort og se dine udbetalinger på dine FDM-rabatter',
            description: 'Når du er logget ind, kan du se og redigere dine kort, der er tilknyttet FDM-fordele samt dine udbetalinger på rabatter.',
            requiresAuth: true
        },
        props: true,
        component: () => import(/* webpackChunkName: "ManageCards" */ '@/views/manageCards/ManageCards'),
    },
    {
        path: '/verify-token',
        name: 'VerifyToken',
        meta: {
            title: 'Verificere',
            noIndex: true,
        },
        props: true,
    },
    {
        path: '/auto-login',
        name: 'AutoLogin',
        meta: {
            title: 'Auto login',
            noIndex: true,
        },
        props: true,
    },
    {
        path: '/404',
        name: 'PageNotFound',
        meta: {
            title: '404 - Siden ikke fundet',
            noIndex: true,
        },
        component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound404'),
    },
    {
        path: '*',
        redirect: '/404',
    },
];

const router = new VueRouter({
    mode: 'history',
    // base url HERE
    routes
});

// Guard
router.beforeEach((to, from, next) => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    let user = Vue.prototype.$user;
    let entryManager = Vue.prototype.$entryManager;

    user.authenticate();
    entryManager.setEntryPage(to.name);

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!user.isAuth()) {
            from.name === 'LinkCard' ? stop() : next({name: 'LinkCard'});
        } else {
            user.getUser();

            if (to.name === 'ManageCards' && (!user.hasEverLinkedCard() && !user.hasCardlessTransactions())) {
                from.name === 'LinkCard' ? stop() : next({name: 'LinkCard'});
            } else {
                next();
            }
        }
    } else {
        if (to.name === 'VerifyToken') {
            if (to.query.code && to.query.state) {
                next({name: 'LinkCard', params: {
                    code: to.query.code,
                    state: to.query.state,
                    nonce: to.query.nonce
                }});
            } else {
                next({name: 'LinkCard'});
            }
        } else if (to.name === 'AutoLogin') {

            if (to.query.redirect && to.query.redirect === 'administrer-kort') {
                // Set auto login locale storage
                localStorage.setItem(loginConfig.AUTO_LOGIN_WITH_REDIRECT_KEY, 'ManageCards');
            }

            next({
                name: 'LinkCard', params: {
                    autoLogin: true
                }
            });
        } else {
            next();
        }
    }
});

// GTM Track page view change (if enabled in additional config settings).
router.beforeEach((to, from, next) => {
    if (GoogleTagManagerConfig.CONTAINER_ID) {
        gtag('config', GoogleTagManagerConfig.CONTAINER_ID, { 'page_path': to.path });
    }

    next();
});

export default router;
import CookieConsentConfig from '@/configs/cookie-consent-config.js';

function initializeCookieConsent() {
    if (CookieConsentConfig.ENABLE_COOKIE_CONSENT) {
        // Create a script element.
        const scriptElement = document.createElement('script');

        // Set the attributes.
        scriptElement.id = 'CookieConsent';
        scriptElement.src = '//policy.app.cookieinformation.com/uc.js';
        scriptElement.setAttribute('data-culture', 'DA');
        scriptElement.type = 'text/javascript';

        // Append the script element to the document body.
        document.body.appendChild(scriptElement);
    }
}

export { initializeCookieConsent };
import { render, staticRenderFns } from "./AlertGlobal.vue?vue&type=template&id=0207a567&scoped=true&"
import script from "./AlertGlobal.vue?vue&type=script&lang=js&"
export * from "./AlertGlobal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0207a567",
  null
  
)

export default component.exports